import contact from "./lib/contact";
import rebox from "./lib/rebox";
import { toggleBelowFold, scrollSpy, smoothScroll } from "./lib/scroll";

$(document).ready(function () {
    contact("#contact-form");
    rebox(".instanode-headstart-thumbimage");
    toggleBelowFold();
    // scrollSpy('.header-nav-sub:visible', {offset: 0})
    // smoothScroll('a[href^="#"]', {offset: 0})
});

$(document).scroll(function () {
    var scroll = $(this).scrollTop();
    if (scroll > 0) {
        $(".header--wrapper").addClass("fixed");
    } else {
        $(".header--wrapper").removeClass("fixed");
    }
});

$(document).ready(function () {
    if ($(window).width() >= 768) {
        $("#pumpentypen").hover(function () {
            $("#pumpentypen-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#pump-design").hover(function () {
            $("#pump-design-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#pumping-media").hover(function () {
            $("#pumping-media-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#hermetic").hover(function () {
            $("#hermetic-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#applications").hover(function () {
            $("#applications-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#contact").hover(function () {
            $("#contact-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#training").hover(function () {
            $("#training-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });

        $("#pump-technology").hover(function () {
            $("#pump-technology-content").toggleClass("active");
            $(".content-circle").toggleClass("active");
        });
    }
});
